<template>
	<div>
		<Header />
		<div class="line-box">
			<div class="container mt-5 mb-5">
				<div class="member-box">
					<h4>Members</h4>
					<form style="margin-top: 30px;">
					  <div class="form-group">
					    <label for="exampleInputEmail1">Username</label>
					    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
					    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
					  </div>
					  <div class="form-group">
					    <label for="exampleInputPassword1">Password</label>
					    <input type="password" class="form-control" id="exampleInputPassword1">
					  </div>
					  <div class="form-group form-check">
					    <input type="checkbox" class="form-check-input" id="exampleCheck1">
					    <label class="form-check-label" for="exampleCheck1">Check me out</label>
					  </div>
					  <button type="submit" class="btn btn-warning">Login</button>
					</form>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Header from '@/components/Header'
	import Footer from '@/components/Footer'
	export default {
		components: {
			Header,
			Footer
		}
	}
</script>

<style lang="scss" scoped>
	.member-box {
		width: 450px;
		box-shadow: 0 1px 5px #333333;
		padding: 15px;
		h4 {
			
		}
	}
</style>
