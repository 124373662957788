<template>
  <div>
		<Header />
		<div class="line-box">
			<div class="container">
				<div class="input-group input-group-lg mb-5 mt-3">
				  <input
						v-model="searchVal"
						type="text"
						class="form-control"
						placeholder="Search name, membership ID...">
				  <div class="input-group-append">
				    <span class="input-group-text" @click="clickFun" style="background-color: #333; color: #FFF; cursor: pointer;">Check</span>
				  </div>
				</div>
				
				<div class="search-box">
					<h4 class="bottom-border" v-if="isResult">0 Result Found</h4>
					<SearchResult v-if="!isResult" />
				</div>
				
			</div>
		</div>
		<Footer />
  </div>
</template>

<script>
	import Header from '@/components/Header'
	import Footer from '@/components/Footer'
	import SearchResult from '@/components/search-result'
	export default {
		components: {
			Header,
			Footer,
			SearchResult
		},
		mounted() {
			if(this.$route.params.r.toLowerCase() == 'miro' || this.$route.params.r.toLowerCase() == 'miroslav') {
				this.isResult = false
			} else {
				this.isResult = true
			}
		},
		data() {
			return {
				isResult: true,
				searchVal: ''
			}
		},
		methods: {
			clickFun() {
				if(this.searchVal == "") {
					this.searchVal = " "
				}
				if(this.searchVal.toLowerCase() == 'miro' || this.searchVal.toLowerCase() == 'miroslav') {
					this.isResult = false
				} else {
					this.isResult = true
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.search-box {
		display: flex;
		flex-direction: column;
		margin-bottom: 50px;
		border: 0px solid #333;
	}
</style>
