<template>
	<div>
		<Header />
		<div class="line-box">
			<div class="container mt-5 mb-5 about-box">
				<p>The Aesthetic Medicine Practitioners (AMP) Association is the global community supporting doctors practising aesthetic medicine. </p>
				<p>The community provides the training, conferences, peer knowledge sharing, career advices to enable the leadership and professional development in all stages of the practitioners' career path.</p>
				<p>As a community, we know how difficult it is for you to identify the right development opportunities and how lonely this whole journey it is by yourself. Therefore, we created this community for you to connect, share and develop. </p>
				<img src="../assets/imgs/about.jpg" class="d-block w-100" alt="">
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Header from '@/components/Header'
	import Footer from '@/components/Footer'
	export default {
		components: {
			Header,
			Footer
		}
	}
</script>

<style lang="scss">
</style>
