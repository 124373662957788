<template>
	<div>
		<Header />
		<div class="line-box">
			<div class="container mt-5 mb-5">
				<Edu />
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Header from '@/components/Header'
	import Footer from '@/components/Footer'
	import Edu from '@/components/Edu'
	export default {
		components: {
			Header,
			Footer,
			Edu
		}
	}
</script>

<style lang="scss">
</style>
