<template>
	<div>
		<Header />
		<div class="line-box">
			<div class="container mt-5 mb-5">
				<News />
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Header from '@/components/Header'
	import Footer from '@/components/Footer'
	import News from '@/components/News'
	export default {
		components: {
			Header,
			Footer,
			News
		}
	}
</script>

<style lang="scss">
</style>
