<template>
	<div>
		<Header />
		<div class="line-box">
			<div class="container mt-5 mb-5">
				<div class="card mb-3">
				  <div class="row no-gutters">
				    <div class="col-md-2">
				      <img src="../assets/imgs/c1.jpg" alt="">
				    </div>
				    <div class="col-md-8">
				      <div class="card-body">
				        <h5 class="card-title">LIVE OLYMIA LONDON 12-13 FEB 2022</h5>
				        <p class="card-text">The New Era of Aesthetic Medicine</p>
				      </div>
				    </div>
				  </div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Header from '@/components/Header'
	import Footer from '@/components/Footer'
	export default {
		components: {
			Header,
			Footer
		}
	}
</script>

<style lang="scss">
</style>
