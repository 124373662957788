<template>
	<div>
		<Header />
		<div class="line-box">
			<div class="container mt-5 mb-5">
				<div class="card-group mb-5">
				  <div class="card">
				    <img src="../assets/imgs/j1.jpg" class="card-img-top" alt="...">
				    <div class="card-body">
				      <p class="card-text">Aesthetic Medicine Doctor in Milan</p>
				    </div>
				  </div>
				  <div class="card">
				    <img src="../assets/imgs/j2.jpg" class="card-img-top" alt="...">
				    <div class="card-body">
				      <p class="card-text">Med Spa Medical Director in New York</p>
				    </div>
				  </div>
				  <div class="card">
				    <img src="../assets/imgs/j2.jpg" class="card-img-top" alt="...">
				    <div class="card-body">
				      <p class="card-text">Med Spa Aesthetic Nurse in New York</p>
				    </div>
				  </div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Header from '@/components/Header'
	import Footer from '@/components/Footer'
	export default {
		components: {
			Header,
			Footer
		}
	}
</script>

<style lang="scss">
</style>
